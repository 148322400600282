import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'

import NavHeader from '../nav/nav-header'
import shortid from 'shortid'
import { FORM_STATES } from '../../constants/helper-states'
import { upsertForm } from '../../reducers/form'
import { resetPasswordWithCode } from '../../actions/auth'
import { routes } from '../../constants/routes'

const mapStateToProps = (state) => {
    return {
        forms: state.forms,
        authCode: state.authCode
    }
}

const PasswordReset = ({ dispatch, navigate, fireauth, forms, authCode }) => {
    const [formId] = useState(shortid.generate())
    const [password, setPassword] = useState('')
    const activeForm = forms.find(f => f.id === formId)
    const activeFormStatus = activeForm?.status

    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
    }, [dispatch, formId])

    useEffect(() => {
        if (activeFormStatus === FORM_STATES.SUCCESS) {
            navigate(routes.router.path)
        }
    }, [dispatch, activeFormStatus, formId, navigate])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        dispatch(resetPasswordWithCode(fireauth, authCode.authCode, authCode.authTempEmail, password, formId))
    }

    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <h1 className="txt-center">Reset Password</h1>
                <form onSubmit={handleSubmit}>
                    <div className="input-wrapper">
                        <label>password<span className="required">*</span></label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(evt) => setPassword(evt.target.value)}
                            placeholder="Enter new password"
                            required />
                    </div>
                    <div className="input-wrapper">
                        <button type="submit" className="btn submit">Submit</button>
                    </div>
                    {activeForm && activeForm.status === FORM_STATES.ERROR ? <p className="txt-center error">{activeForm.body}</p> : null}
                </form>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(PasswordReset))
