import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
// import GameMenu from './game-menu'
import GameBoard from './game-board'
import ItemList from './item-list'
import NavHeader from '../../nav/nav-header'
import Modal from '../../utils/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectGames = state => state.games
const selectGameCode = (state, gameCode) => gameCode
const selectEntries = state => state.entries
const selectUser = state => state.user
const selectPlayers = state => state.players

const makeGame = createSelector(
    [selectGames, selectGameCode],
    (games, gameCode) => games.find(game => game.code === gameCode)
)

const makeGameEntries = createSelector(
    [selectEntries, selectGames, selectGameCode],
    (entries, games, gameCode) => {
        const game = games.find(g => g.code === gameCode)
        return entries.filter(entry => entry.gameId === game.id)
            .sort((a, b) => {
                if (a.displayOrder > b.displayOrder) {
                    return 1
                }
                return -1
            })
    }
)

const makeGamePlayer = createSelector(
    [selectPlayers, selectUser, selectGames, selectGameCode],
    (players, user, games, gameCode) => {
        const game = games.find(g => g.code === gameCode)
        return players.find(player => player.userId === user.id && player.gameId === game.id)
    }
)

const makePlayers = createSelector(
    [selectPlayers, selectGames, selectGameCode],
    (players, games, gameCode) => {
        const game = games.find(g => g.code === gameCode)
        return players.filter(player => player.gameId === game.id)
    }
)

const mapStateToProps = (state, ownProps) => {
    return {
        game: makeGame(state, ownProps.params.gameId),
        entries: makeGameEntries(state, ownProps.params.gameId),
        player: makeGamePlayer(state, ownProps.params.gameId),
        players: makePlayers(state, ownProps.params.gameId)
    }
}

const POSSIBLE_BINGOS = [
    [0, 1, 2, 3, 4],
    [5, 6, 7, 8, 9],
    [10, 11, 12, 13],
    [14, 15, 16, 17, 18],
    [19, 20, 21, 22, 23],
    [0, 5, 10, 14, 19],
    [1, 6, 11, 15, 20],
    [2, 7, 16, 21],
    [3, 8, 12, 17, 22],
    [4, 9, 13, 18, 23],
    [0, 6, 17, 23],
    [4, 8, 15, 19]
]

const ViewGame = ({ dispatch, fireauth, params, navigate, game, entries, player, players }) => {
    const [ activeEntryId, setActiveEntryId ] = useState(null)
    const [ hasBingo, setHasBingo ] = useState(false)
    const [ showBingoModal, setShowBingoModal ] = useState(false)
    const gameCode = params.gameId
    const markedEntries = entries.filter(entry => entry.isMarked)
    const boardEntries = player?._computed?.boardEntries
    const gameStatus = game?.status
    const winner = players?.find(p => p.id === game?.winnerId)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('games', 'VIEW', gameCode)))
    }, [dispatch, gameCode])

    useEffect(() => {
        if (gameStatus === 'BINGO') {
            setShowBingoModal(true)
        }
    }, [gameStatus])

    useEffect(() => {
        if (boardEntries && markedEntries.length > 2) {
            // get the indexes of the marked entries from the board entries
            let markedIndexes = markedEntries.map(entry => boardEntries.indexOf(entry.id))
            // loop over possible bingos and see if all the indexes are in the marked indexes
            let bingo = POSSIBLE_BINGOS.find(bingoSet => bingoSet.every(idx => markedIndexes.includes(idx)))
            if (bingo) {
                setHasBingo(true)
            } else {
                setHasBingo(false)
            }
        }
    }, [markedEntries.length, boardEntries])

    useEffect(() => {
        if (activeEntryId) {
            let entry = document.getElementById(`entry-${activeEntryId}`)
            entry.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [activeEntryId])

    useEffect(() => {
        if (game && game.status === 'SETUP') {
            return navigate(buildRoutePath(routes.setupGame.path, { gameId: gameCode }))
        }
    }, [game, gameCode, navigate])

    if (!game) {
        return (
            <div className="racoon-loader">
                <div>Loading Game...</div>
                <img src="/assets/img/racoon-01.svg" alt="Loading..." />
            </div>
        )
    }


    return (
        <>
            <div className="game-page">
                <NavHeader peopleIcon={true} />
                <GameBoard player={player} entries={entries} activeEntryId={activeEntryId} onSetActiveEntryId={setActiveEntryId} />
                <ItemList entries={entries} player={player} game={game} activeEntryId={activeEntryId} onSetActiveEntryId={setActiveEntryId} hasBingo={hasBingo} />
            </div>
            { showBingoModal &&
                <Modal extraClass="red" closeAction={() => setShowBingoModal(false)} hideClose={true}>
                    <div className="modal-column">
                        <span>
                            <FontAwesomeIcon icon="hands-clapping" size="3x" />
                        </span>
                        <span className="winner">
                            {winner.screenName}<br />
                            called bingo
                        </span>
                        <Link to={routes.createGame.path} className="btn white-on-red sm">
                            Start New Game
                        </Link>
                        <span className="small-close" onClick={() => setShowBingoModal(false)}>close</span>
                    </div>
                </Modal>
            }
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewGame))
