import React from 'react'
import { connect } from 'react-redux'
import GameTile from './game-tile'
import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

const GameBoard = ({ dispatch, player, entries, activeEntryId, onSetActiveEntryId }) => {
    if (!player || !entries || !player._computed?.boardEntries) {
        return (
            <div className="racoon-loader">
                <div>Loading Game...</div>
                <img src="/assets/img/racoon-01.svg" alt="Loading..." />
            </div>
        )
    }

    const handleSelectTile = (entryId) => {
        onSetActiveEntryId(entryId)
    }

    const handleMarkTile = (myEntry) => {
        let isMarker = myEntry.markedBy === player.id
        if (myEntry.isMarked && !(player.role === 'CREATOR' || isMarker)) {
            alert('Only the game creator or the person who marked this item can unmark it')
        } else {
            // this both marks and unmarks
            let entry = { ...myEntry, markedBy: player.userId, userId: player.userId }
            dispatch(callApi(apiResourceEndpoint('entries', 'UPDATE', myEntry.id), entry))
        }
    }

    const renderTiles = () => {
        let allTiles = []
        player._computed.boardEntries.forEach((boardEntry, idx) => {
            const gameEntry = entries.find(entry => entry.id === boardEntry)
            let rightBorder = idx + 1 % 5 === 0 ? '' : 'rightBorder'
            let bottomBorder = idx + 1 < 20 ? 'bottomBorder' : ''
            if (idx + 1 === 13) {
                allTiles.push(
                    <div key="free-space" className={`tile ${rightBorder} ${bottomBorder}`}>
                        <div className="free">free</div>
                    </div>
                )
            }
            if (gameEntry) {
                allTiles.push(
                    <GameTile
                        key={gameEntry.id}
                        gameEntry={gameEntry}
                        rightBorder={rightBorder}
                        bottomBorder={bottomBorder}
                        activeEntryId={activeEntryId}
                        onHandleSelectTile={handleSelectTile}
                        onHandleMarkTile={handleMarkTile} />
                )
            }
        })
        return allTiles
    }

    return (
        <div className="game-board">
            <div className="tile-wrapper">
                {renderTiles()}
            </div>
        </div>
    )
}

export default connect()(GameBoard)
