import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {}

const firstTime = createSlice({
    name: 'firstTime',
    initialState: INITIAL_STATE,
    reducers: {
        saveGameCount(state, action) {
            console.log('in here?')
            console.log(action)
            state.gameCount = action.payload.gameCount
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})
export const { saveGameCount } = firstTime.actions
export default firstTime.reducer
