import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { handleFirebaseAuth, killState } from '../actions/auth'
import { removeAuth } from '../reducers/auth'

import { routes, nestedRoutePath } from '../constants/routes'

import AuthLogin from './auth/login'
import AuthEmailRegister from './auth/email-register'
import AuthPasswordHelp from './auth/password-help'
import AuthHandler from './auth/auth-handler'
import AuthPasswordReset from './auth/password-reset'
import AuthLogout from './auth/logout'
import AuthRouter from './auth/router'
import AuthRegistration from './auth/auth-registration'
import DevTools from './utils/dev-tools'
import Paywall from './game/paywall'
import PublicIndex from './public/index'
import GamesIndex from './game/index'

import { isEmpty } from 'lodash'

import '../assets/scss/app.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUserCog, faTimes, faCheck, faCaretDown, faArrowRight, faInfoCircle, faBars, faEllipsis, faUser, faEllipsisH,
    faToggleOn, faToggleOff, faEye, faEyeSlash, faUsers, faCog, faList, faTrashAlt, faDownload, faExclamationTriangle, faHandsClapping
} from '@fortawesome/free-solid-svg-icons'
import {
    faUser as farUser
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faUserCog, faTimes, faCheck, faCaretDown, faArrowRight, faInfoCircle, faBars, faEllipsis, faUser, faEllipsisH,
    faToggleOn, faToggleOff, faEye, faEyeSlash, faUsers, faCog, faList, faTrashAlt, faDownload, faExclamationTriangle, faHandsClapping,
    farUser
)

const NON_ROUTER_PAGES = [routes.publicIndex.path, routes.authLogin.path, routes.router.path, routes.authRegister.path, routes.authPasswordReset.path, routes.authLogout.path, routes.createGame.path, routes.joinGame.path]

function AuthenticatedRoute({ children, auth, location }) {
    let isAuthenticated = (!isEmpty(auth) && auth.authId) || false
    return isAuthenticated ? children : <Navigate to={routes.registerAuth.path} state={{ from: location }} />
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const App = ({ dispatch, auth, firebaseApp, location, navigate }) => {
    const [ hasAuthRouted, setHasAuthRouted ] = useState(false)
    const [ shouldRoute, setShouldRoute ] = useState(false)
    const [ fireauth, setFireauth ] = useState(null)
    const pathname = location.pathname

    useEffect(() => {
        const fbAuth = getAuth(firebaseApp)
        setFireauth(fbAuth)

        const unsubscribe = onAuthStateChanged(fbAuth, (firebaseUser) => {
            if (firebaseUser) {
                dispatch(handleFirebaseAuth(firebaseUser.toJSON()))
            } else {
                setHasAuthRouted(false)
                dispatch(killState())
                dispatch(removeAuth())
            }
        })
        return () => unsubscribe()
    }, [dispatch, firebaseApp])

    useEffect(() => {
        if (!hasAuthRouted && !isEmpty(auth) && auth.authId) {
            if (pathname.indexOf('/join') !== -1) {
                console.log('join')
                // do nothing for now...the join should route us
            } else if (pathname.indexOf('/create') !== -1) {
                console.log('create')
                // do nothing for now...the create should route us
            } else if (NON_ROUTER_PAGES.indexOf(pathname) === -1 || pathname.indexOf('/play/') !== -1) {
                console.log('non-router')
                console.log(pathname)
                setHasAuthRouted(true)
                setShouldRoute(true)
            }
        }
    }, [auth, hasAuthRouted, pathname])

    useEffect(() => {
        if (shouldRoute) {
            setShouldRoute(false)
            const fromState = location?.state?.from?.pathname ? location.state.from.pathname : null
            const stateQuery = location?.state?.from?.search ? location.state.from.search : null
            navigate(routes.router.path, { state: { from: { pathname: fromState, search: stateQuery } } })
        }
    }, [shouldRoute, location.state, navigate])

    return (
        <>
            <div id="app-wrapper">
                <div className="app-container">
                    <Routes>
                        <Route path={routes.router.path} element={<AuthenticatedRoute auth={auth} location={location}><AuthRouter /></AuthenticatedRoute>} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.gameIndex.path, true)} element={<AuthenticatedRoute auth={auth} location={location}><GamesIndex fireauth={fireauth} /></AuthenticatedRoute>} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.gamePaywall.path)} element={<AuthenticatedRoute auth={auth} location={location}><Paywall fireauth={fireauth} /></AuthenticatedRoute>} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.authLogin.path)} element={<AuthLogin fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.authRegister.path)} element={<AuthEmailRegister fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.authPasswordHelp.path)} element={<AuthPasswordHelp fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.authHandler.path)} element={<AuthHandler fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.authPasswordReset.path)} element={<AuthPasswordReset fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.registerAuth.path)} element={<AuthRegistration fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.authLogout.path, true)} element={<AuthLogout fireauth={fireauth} />} />
                        <Route path={nestedRoutePath(routes.publicIndex.path, routes.publicIndex.path, true)} element={<PublicIndex fireauth={fireauth} />} />
                    </Routes>
                </div>
            </div>
            <DevTools />
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(App))
