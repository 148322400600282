import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Modal = ({ closeAction, children, extraClass, hideClose }) => {
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const handleClose = () => {
        if (closeAction) {
            closeAction()
        }
    }

    return (
        <div className="modal show d-block">
            <div className={`modal-dialog ${extraClass ? extraClass : ''}`}>
                <div className="modal-content">
                    { !hideClose &&
                        <span onClick={handleClose} className="close-icon">
                            <FontAwesomeIcon icon="times" title="Close Modal" />
                        </span>
                    }
                    {children}
                </div>
            </div>
            <div className="modal-backdrop" onClick={handleClose} />
        </div>
    )
}

export default Modal
