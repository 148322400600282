// import firebase from 'firebase/app'
import { upsertForm } from '../reducers/form'
import { FORM_STATES } from '../constants/helper-states'

import { signOut, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset, signInAnonymously, createUserWithEmailAndPassword, linkWithCredential, EmailAuthProvider } from 'firebase/auth'
import { saveAuth } from '../reducers/auth'
import { saveAuthHandler, saveAuthHandlerError } from '../reducers/auth-code'
// const fireauth = getAuth()

export const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

export const saveAnonymousAuth = (auth) => {
    return {
        type: `SAVE_ANONYMOUS_AUTH`,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: `REMOVE_AUTH`
    }
}

export const connectToPusherWebsocket = (fireauth) => {
    return fireauth.currentUser.getIdToken()
        .then(token => {
            return {
                Authorization: `Bearer ${token}`
            }
        }).catch(err => {
            console.log('unable to get authorization info')
        })
}

export const handleFirebaseAuthCode = (auth, code, action) => {
    return dispatch => {
        verifyPasswordResetCode(auth, code)
            .then(email => {
                dispatch(saveAuthHandler({ code, action, email, isAnonymous: false }))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const anonymousSignIn = (fireauth) => {
    return dispatch => {
        signInAnonymously(fireauth)
            .then(() => {
                console.log('created an anonymous user')
                // dispatch(upsertFetching(fetchingId, fetchingStates.COMPLETE))
            })
            .catch(err => {
                console.log('error with anonymous')
            })
    }
}

export const registerWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.SUCCESS }))
            })
            .catch(err => {
                console.log(err)
                dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `${err.message}` }))
            })
    }
}

export const registerEmailAndPassAndLink = (fireauth, email, password, formId) => {
    return dispatch => {
        const credential = EmailAuthProvider.credential(email, password)
        const fireUser = fireauth.currentUser
        linkWithCredential(fireUser, credential)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `${err.message}` }))
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `${err.message}` }))
            })
    }
}

export const generatePasswordResetLink = (fireauth, email, formId) => {
    return dispatch => {
        sendPasswordResetEmail(fireauth, email)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `${err.message}` }))
            })
    }
}

export const resetPasswordWithCode = (auth, code, email, password, formId) => {
    return dispatch => {
        return confirmPasswordReset(auth, code, password)
            .then(() => {
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        dispatch(upsertForm({ id: formId, status: FORM_STATES.SUCCESS }))
                    })
                    .catch(err => {
                        console.log('in here with an error?', err)
                    })
            })
            .catch(err => {
                console.log('in here?', err)
                dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `${err.message}` }))
            })
    }
}

export const updateAuthPassword = (fireauth, formData, formId) => {
    return dispatch => {
        if (formData.newPassword !== formData.confirmPassword) {
            dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: 'New passwords do not match.' }))
            return false
        }
        const fireUser = fireauth.currentUser
        const credentials = fireauth.EmailAuthProvider.credential(fireUser.email, formData.existingPassword)
        return fireUser.reauthenticateWithCredential(credentials)
            .then(() => {
                fireUser.updatePassword(formData.newPassword)
                    .then(() => {
                        dispatch(upsertForm({ id: formId, status: FORM_STATES.SUCCESS }))
                    })
                    .catch(err => {
                        dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `Error: ${err.message}` }))
                    })
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: FORM_STATES.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleSignOut = (fireauth) => {
    return dispatch => {
        dispatch(killState())
        signOut(fireauth)
            .then(() => {
                // do nothing?
                // console.log('signed out')
                // dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}
