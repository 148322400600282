import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchApiDataIfNeeded } from '../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../constants/routes'
import { createSelector } from '@reduxjs/toolkit'
import NavHeader from '../nav/nav-header'

const selectGames = state => state.games
const selectStatusOrder = state => ({ PLAY: 1, SETUP: 2, COMPLETE: 3 })

const makeGames = createSelector(
    [selectGames, selectStatusOrder],
    (games, statusOrder) => {
        return games.filter(g => g).sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
            .sort().sort((a, b) => {
                if (a.status === b.status) {
                    return a.name.localeCompare(b.name)
                }
                return a.status.localeCompare(b.status)
            })
    }
)

const mapStateToProps = (state) => {
    return {
        games: makeGames(state)
    }
}

const ListGames = ({ dispatch, games }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('games', 'LIST')))
    }, [dispatch])

    const renderGames = () => {
        let allGames = []
        games.forEach(game => {
            const gamePath = game.status === 'SETUP'
                ? buildRoutePath(routes.setupGame.path, { gameId: game.code })
                : buildRoutePath(routes.gameView.path, { gameId: game.code })
            allGames.push(
                <div key={game.id} className="game">
                    <Link to={gamePath}>
                        <span className="game-name">{game.name}</span>
                        <span className={`game-status ${game.status.toLowerCase()}`}>{game.status}</span>
                    </Link>
                </div>
            )
        })
        if (allGames.length > 0) {
            return (
                <>
                    <div className="games-list">
                        {allGames}
                    </div>
                    <Link className="btn black" to={routes.createGame.path}>
                        Create a Game
                    </Link>
                </>
            )
        }
        return (
            <div>
                <p className="txt-center">No games found.</p>
                <Link className="btn black" to={routes.createGame.path}>
                    Create a Game
                </Link>
                <br />
                <Link className="btn text-only" to={buildRoutePath(routes.joinGame.path, { gameId: null })}>
                    Have an invite? Join a game
                </Link>
            </div>
        )
    }

    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content create-a-game">
                <h1 className="txt-center mb-2">Games</h1>
                {renderGames()}
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(ListGames)
