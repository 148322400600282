import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'
import { apiResourceEndpoint, routes } from '../../constants/routes'
import { fetchApiDataIfNeeded } from '../../actions/api'
import { isEmpty } from 'lodash'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

const AuthRouter = ({ dispatch, location, user }) => {
    const [readyToRoute, setReadyToRoute] = useState(false)
    const [hasTimedOut, setHasTimedOut] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setHasTimedOut(true)
        }, 5000)
        return () => clearTimeout(timeout)
    }, [hasTimedOut])

    useEffect(() => {
        if (isEmpty(user)) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user/load-details', 'LIST')))
        }
        if (user && user?.id > 0) {
            setReadyToRoute(true)
        }
    }, [dispatch, user])


    if (readyToRoute) {
        if (location.state && location.state.from.pathname) {
            let queryParam = ''
            if (location.state.from.search) {
                queryParam = location.state.from.search
            }
            return <Navigate to={location.state.from.pathname + queryParam} />
        }
        return <Navigate to={routes.gameIndex.path} />
    }
    if (hasTimedOut) {
        return (
            <div className="racoon-loader">
                <div>Uh oh.... something went wrong.</div>
                <img src="/assets/img/racoon-02.svg" alt="Loading..." />
            </div>
        )
    }
    return (
        <div className="racoon-loader">
            <div>Loading....</div>
            <img src="/assets/img/racoon-01.svg" alt="Loading..." />
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
