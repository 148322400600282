import React from 'react'

const HowToPlay = () => {
    return (
        <div className="how-to-play">
            <h1 className="txt-center mb-2">How To Play</h1>
            <h2>Create a Game</h2>
            <p>Name your game and invite your friends/family to help add entries to the board. 'Entries' are fun predictions
            about what people might say or what might happen at a gathering (eg., Dad starts talking football). 24 entries to start.</p>
            <h2>Player Invitations</h2>
            <p>Click on the people icon to 'Copy / Share Link' to send via text or email. Use the code to join a game.</p>
            <h2>Playing Shady Bingo</h2>
            <p>Tap once to select an entry, tap twice to mark/unmark.
                This entry will also be marked on everyone else's boards.
                Only the game creator or the player who marked the entry can unmark an entry. The first player to get 5 in a row and declare 'Bingo!' wins.</p>
        </div>
    )
}

export default HowToPlay
