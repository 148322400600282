import React from 'react'
import NavHeader from '../nav/nav-header'
import HowToPlay from './how-to-play'

const HowTo = () => {
    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <HowToPlay />
            </div>
        </div>
    )
}

export default HowTo
