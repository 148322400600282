import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'

import { routes, nestedRoutePath } from '../../constants/routes'

import Welcome from './welcome'
import CreateGame from './create'
import JoinGame from './join'
import PlayerName from './player-name'
import SetupGame from '../game/play/setup'
import About from './about'
import HowTo from './how-to'

function PaywalledGameCreation({ children, user, firstTime }) {
    let bypassPaywall = false
    console.log(firstTime)
    if (!user || !user.hasCreated || user.paid || firstTime.gameCount === 0) {
        bypassPaywall = true
    }
    return bypassPaywall ? children : <Navigate to={routes.gamePaywall.path} />
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        firstTime: state.firstTime
    }
}

const PublicIndex = ({ fireauth, user, firstTime }) => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.publicIndex.path, routes.createGame.path)} element={<PaywalledGameCreation user={user} firstTime={firstTime}><CreateGame fireauth={fireauth} /></PaywalledGameCreation>} />
            <Route path={nestedRoutePath(routes.publicIndex.path, routes.joinGame.path)} element={<JoinGame fireauth={fireauth} />} />
            <Route path={nestedRoutePath(routes.publicIndex.path, routes.setupGame.path)} element={<SetupGame />} />
            <Route path={nestedRoutePath(routes.publicIndex.path, routes.playerName.path)} element={<PlayerName />} />
            <Route path={nestedRoutePath(routes.publicIndex.path, routes.about.path)} element={<About />} />
            <Route path={nestedRoutePath(routes.publicIndex.path, routes.howTo.path)} element={<HowTo />} />
            <Route index element={<Welcome />} />
        </Routes>
    )
}

export default connect(mapStateToProps)(PublicIndex)
