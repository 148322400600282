import apiCacheReducer from './api-cache'
import authReducer from './auth'
import authCodeReducer from './auth-code'
import firstTimeReducer from './first-time'
import formReducer from './form'
import genericSlice from './generic-slice'
import pagerReducer from './pager'


const indexReducer = {
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    configs: genericSlice('CONFIGS'),
    entries: genericSlice('ENTRIES'),
    firstTime: firstTimeReducer,
    forms: formReducer,
    games: genericSlice('GAMES'),
    onlinePlayers: genericSlice('PLAYERS_PRESENCE'),
    paginator: pagerReducer,
    payments: genericSlice('PAYMENTS'),
    paymentIntent: genericSlice('PAYMENT_INTENT'),
    players: genericSlice('PLAYERS'),
    user: genericSlice('USER', {})
}

export default indexReducer
