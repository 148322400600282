import React, { useEffect } from 'react'
import { withRouterProps } from '../utils/with-router-props'

import { routes } from '../../constants/routes'

const AuthRegistration = ({ navigate }) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate(routes.publicIndex.path)
        }, 5000)
        return () => clearTimeout(timeout)
    }, [navigate])

    return (
        <div className="racoon-loader">
            <div>Loading Auth...</div>
            <img src="/assets/img/racoon-01.svg" alt="Loading..." />
        </div>
    )
}

export default withRouterProps(AuthRegistration)
