import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { upsertForm } from '../../../reducers/form'
import shortid from 'shortid'
import { FORM_STATES } from '../../../constants/helper-states'
import EntryItem from './entry-item'
import NavHeader from '../../nav/nav-header'

const selectGames = state => state.games
const selectEntries = state => state.entries
const selectGameCode = (state, gameCode) => gameCode
const selectPlayers = state => state.players
// const selectUserId = (state, user) => user.id
const selectGameId = (state, game) => game?.id

const makeGame = createSelector(
    [selectGames, selectGameCode],
    (games, gameCode) => games.find(game => game.code === gameCode)
)

const makeEntries = createSelector(
    [selectEntries, selectGameCode],
    (entries, gameCode) => entries
        .filter(entry => entry._computed.gameCode === gameCode)
        .sort((a, b) => {
            if (a.displayOrder > b.displayOrder) {
                return 1
            }
            return -1
        })
)

const makeGamePlayers = createSelector(
    [selectPlayers, selectGameId],
    (players, gameId) => players.filter(player => player.gameId === gameId)
)

// const makeMyPlayer = createSelector(
//     [selectPlayers, selectUserId],
//     (players, userId) => players.find(player => player.userId === userId)
// )

const mapStateToProps = (state, ownProps) => {
    const gameCode = ownProps.params.gameId
    const game = makeGame(state, gameCode)
    const gamePlayers = makeGamePlayers(state, game)
    // const user = state.user
    return {
        auth: state.auth,
        entries: makeEntries(state, gameCode),
        forms: state.forms,
        game,
        gamePlayers,
        user: state.user
    }
}

const SetupGame = ({ dispatch, params, auth, game, forms, entries, gamePlayers, user, navigate }) => {
    const [ formId ] = useState(shortid.generate())
    const [ entryText, setEntryText ] = useState('')
    const gameCode = params.gameId
    const activeForm = forms.find(f => f.id === formId)
    const myPlayer = gamePlayers.find(player => player.userId === user.id)
    const gameStatus = game?.status

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('games', 'VIEW', gameCode)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('entries', 'LIST'), { game: gameCode }))
    }, [dispatch, gameCode])

    useEffect(() => {
        if (activeForm && activeForm.status === FORM_STATES.SUCCESS) {
            dispatch(upsertForm({ id: formId, status: FORM_STATES.READY }))
            setEntryText('')
        }
    }, [dispatch, activeForm, formId])

    useEffect(() => {
        if (gameStatus === 'PLAY') {
            navigate(buildRoutePath(routes.gameView.path, { gameId: gameCode }))
        }
    }, [gameStatus, gameCode, navigate])

    if (!game) {
        return (
            <div className="racoon-loader">
                <div>Loading Game...</div>
                <img src="/assets/img/racoon-01.svg" alt="Loading..." />
            </div>
        )
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (evt.target.entry.value.trim() !== '') {
            dispatch(upsertForm({ id: formId, status: FORM_STATES.PROCESSING }))
            const payload = {
                gameCode: game.code,
                text: entryText
            }
            dispatch(callApi(apiResourceEndpoint('entries', 'CREATE'), payload, formId))
        }
    }

    const renderEntries = () => {
        let allEntries = []
        entries.forEach(entry => {
            allEntries.push(
                <EntryItem key={entry.id} entry={entry} game={game} player={myPlayer} />
            )
        })
        return (
            <div className="entries-wrapper">
                <div className="entries-list">
                    {allEntries}
                </div>
            </div>
        )
    }

    const generateGameBoards = () => {
        if (entries.length < 24) {
            return
        }
        dispatch(callApi(apiResourceEndpoint('games', 'UPDATE', game.code, 'start-game'), null, -1))
            .then(() => {
                navigate(buildRoutePath(routes.gameView.path, { gameId: game.code }))
            })
    }

    const renderActionBtn = () => {
        if (entries.length < 24) {
            return <h3 className="entry-count">{24 - entries.length} more entries needed to start a game</h3>
        }
        return (
            <div className="action">
                <span className="btn submit" onClick={generateGameBoards}>
                    start game
                </span>
                <h3>( Or keep adding entries for more variety! )</h3>
            </div>
        )
    }

    return (
        <div className="setup-pages">
            <NavHeader peopleIcon={true} />
            <div className="content">
                <div className="content-wrapper">
                    <h2 className="game-name">{game.name}</h2>
                    <form onSubmit={handleSubmit} className="mb-1">
                        <div className="input-wrapper stacked">
                            <textarea
                                name="entry"
                                id="entry"
                                rows="3"
                                value={entryText}
                                onChange={(evt) => setEntryText(evt.target.value)}
                                placeholder="Add an entry to the bingo board&#10;e.g., Uncle Zeke asks for more wine"
                                required />
                        </div>
                        <button type="submit" className="btn black">Add Entry</button>
                    </form>
                </div>
                {renderActionBtn()}
                {renderEntries()}
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(SetupGame))
