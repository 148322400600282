import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import NavHeader from '../nav/nav-header'
import CheckoutForm from './checkout-form'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { callApi } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'
import { createSelector } from '@reduxjs/toolkit'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const selectAuth = state => state.auth
const selectPaymentIntent = state => state.paymentIntent

const makePaymentIntent = createSelector(
    [selectAuth, selectPaymentIntent],
    (auth, paymentIntent) => {
        return paymentIntent.find(intent => intent.id === auth.authId)
    }
)

const mapStateToProps = (state) => {
    return {
        paymentIntent: makePaymentIntent(state)
    }
}

const Paywall = ({ dispatch, paymentIntent }) => {
    useEffect(() => {
        dispatch(callApi(apiResourceEndpoint('intent', 'CREATE'), null, -1))
    }, [dispatch])

    const stripeOptions = {
        appearance: {
            theme: 'stripe'
        },
        loader: 'auto',
        clientSecret: paymentIntent?.secret
    }

    return (
        <div className="setup-pages">
            <NavHeader />
            <div className="content">
                <h1 className="txt-center">Purchase a Game</h1>
                <p>
                    We're so thrilled you are enjoying Shady Bingo. To support our game, we ask players to pay $5 if they want to create more than one game.
                </p>
                <p>
                    Your $5 purchase will allow you to create as many games as you want for the next 90 days and play those games indefinitely.
                </p>
                <p>
                    If you don't want to pay, ask your friends to create a game and invite you.
                </p>
                { paymentIntent && paymentIntent.secret &&
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <CheckoutForm paymentIntentId={paymentIntent.paymentIntentId} />
                    </Elements>
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(Paywall)
