import React from 'react'
import { connect } from 'react-redux'

import EntryItem from './entry-item'
import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

const ItemList = ({ dispatch, entries, game, player, activeEntryId, onSetActiveEntryId, hasBingo }) => {
    const handleSelectTile = (entryId) => {
        onSetActiveEntryId(entryId)
    }

    const renderEntries = () => {
        let allEntries = []
        entries.forEach(entry => {
            let onBoard = false
            if (player?._computed?.boardEntries) {
                onBoard = player?._computed.boardEntries.find(b => {
                    return b === entry.id
                })
            }
            allEntries.push(
                <EntryItem
                    key={entry.id}
                    entry={entry}
                    game={game}
                    onBoard={onBoard}
                    player={player}
                    activeEntryId={activeEntryId}
                    onHandleSelectTile={handleSelectTile}
                />
            )
        })
        return allEntries
    }

    const handleCallBingo = () => {
        dispatch(callApi(apiResourceEndpoint('games', 'UPDATE', game.code, 'call-bingo'), null, -1))
    }

    const renderCallBingo = () => {
        if (hasBingo && game.status !== 'BINGO') {
            return (
                <div className="call-bingo">
                    <button className="btn red" onClick={handleCallBingo}>Call Bingo</button>
                </div>
            )
        }
        return null
    }


    return (
        <div className={`entries-wrapper ${hasBingo && game.status !== 'BINGO' ? 'has-bingo' : ''}`}>
            {renderCallBingo()}
            <div className="entries-list">
                {renderEntries()}
            </div>
        </div>
    )
}

export default connect()(ItemList)
