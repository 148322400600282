import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popover from '../../utils/popover'

const EntryItem = ({ dispatch, entry, game, player, onBoard, activeEntryId, onHandleSelectTile }) => {
    const [selected, setSelected] = useState(entry.id === activeEntryId)
    const isAuthor = entry.playerId === player?.id
    const isGameCreator = game.userId === player?.userId
    const entryId = entry.id

    const handleClick = () => {
        if (onHandleSelectTile) {
            if (!selected) {
                onHandleSelectTile(entry.id)
            } else {
                onHandleSelectTile(null)
            }
        }
    }

    useEffect(() => {
        setSelected(entryId === activeEntryId)
    }, [activeEntryId, entryId])

    const handleDeleteEntry = () => {
        let confirmDelete = window.confirm('Are you sure you want to delete this entry?')
        if (confirmDelete) {
            dispatch(callApi(apiResourceEndpoint('entries', 'DELETE', entry.id)))
        }
    }

    const renderEntrySequence = () => {
        if (game.status === 'SETUP') {
            return null
        }
        return (
            <div className={`entry-sequence ${entry.isMarked ? 'marked' : ''}`}>
                <span>{entry.displayOrder}</span>
            </div>
        )
    }

    const renderEntryInfo = () => {
        return (
            <div className="entry-info">
                <div className="entry-text">
                    {entry.text}
                </div>
                <div className="entry-author">
                    {entry._computed.playerName}
                </div>
            </div>
        )
    }

    const renderDeletePopover = () => {
        return (
            <span className="flex-table left-right bordered" onClick={handleDeleteEntry}>
            Delete Entry
            </span>
        )
    }

    const renderEntryActions = () => {
        return (
            <>
                { (isAuthor || isGameCreator) &&
                    <div className="entry-actions">
                        <Popover content={renderDeletePopover()}>
                            <FontAwesomeIcon icon="ellipsis-h" title="Delete" />
                        </Popover>
                    </div>
                }
            </>
        )
    }

    return (
        <div
            id={`entry-${entry.id}`}
            className={`entry ${selected ? 'selected' : ''} ${onBoard ? '' : 'not-on-board'}`}
            onClick={handleClick}>
            {renderEntrySequence()}
            {renderEntryInfo()}
            {renderEntryActions()}
        </div>
    )
}

export default connect()(EntryItem)
