import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { apiResourceEndpoint, routes } from '../../constants/routes'
import { callApi } from '../../actions/api'

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const CheckoutForm = ({ dispatch, navigate, paymentIntentId, user }) => {
    const stripe = useStripe()
    const elements = useElements()

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const userHasPaid = user?.paid

    useEffect(() => {
        if (userHasPaid) {
            navigate(routes.createGame.path)
        }
    }, [navigate, userHasPaid])

    const paymentElementOptions = {
        layout: 'tabs',
        paymentMethodOrder: ['card']
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        // if no elements or stripe, return
        if (!stripe || !elements) {
            return
        }
        setIsLoading(true)

        const payload = { email, paymentIntentId }
        const prepIntent = await dispatch(callApi(apiResourceEndpoint('receipt-email', 'CREATE'), payload, -1, true))
        if (!prepIntent) {
            console.log('nothing?')
            return
        }
        if (prepIntent.message === 'EMAIL_ADDED') {
            const { paymentIntent } = await stripe.confirmPayment({
                elements,
                // confirmParams: {
                //     // Make sure to change this to your payment completion page
                //     return_url: `${process.env.REACT_APP_WEBAPP_URL}${routes.paymentConfirmation.path}`
                // }
                redirect: 'if_required'
            })
            const paymentStatus = paymentIntent?.status
            if (paymentStatus === 'succeeded') {
                dispatch(callApi(apiResourceEndpoint('store-payment', 'CREATE'), { paymentIntentId: paymentIntent.id }, -1))
            } else {
                setMessage('Payment failed')
            }
            setIsLoading(false)
        }
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="input-wrapper">
                <label>Email Address for Receipt</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="user@example.com"
                    onChange={(evt) => setEmail(evt.target.value)}
                    required />
            </div>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <br />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="btn submit">
                {isLoading ? 'Processing...' : 'Pay $5'}
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}

export default withRouterProps(connect(mapStateToProps)(CheckoutForm))
