import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Popover = ({ children, content, disabled, resetInput }) => {
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef(null)

    useEffect(() => {
        const handleOutsideBlockClick = (evt) => {
            if (menuRef.current && !menuRef.current.contains(evt.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('click', handleOutsideBlockClick, true)
        return () => {
            document.removeEventListener('click', handleOutsideBlockClick, true)
        }
    }, [])

    useEffect(() => {
        if (!isOpen && resetInput) {
            resetInput()
        }
    }, [isOpen, resetInput])

    const toggleOpen = () => {
        setIsOpen(true)
    }

    if (disabled) {
        return <>{children}</>
    }

    return (
        <div className="popover-wrapper" ref={menuRef}>
            <div className="display" onClick={toggleOpen}>
                {children}
            </div>
            {isOpen &&
                <div className="popover mt-5">
                    <div className="popover-closer">
                        <span onClick={() => setIsOpen(false)}>
                            <FontAwesomeIcon icon="times" />
                        </span>
                    </div>
                    <div className="popover-content">
                        {content}
                    </div>
                </div>
            }
        </div>
    )
}
export default Popover
